import { Component, Injector } from '@angular/core';

import { BaseLayoutComponent } from '../shared/base-layout.component';

import { navItems } from './_nav';

@Component({
  selector: 'app-dashboard',
  templateUrl: './convenio-layout.component.html',
})
export class ConvenioLayoutComponent extends BaseLayoutComponent {

  constructor(
    protected injector: Injector
  ) {
    super(injector, navItems);
  }
}
