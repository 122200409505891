// Angular
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, registerLocaleData, LocationStrategy, HashLocationStrategy } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClientJsonpModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { APP_INITIALIZER, NgModule, Optional, SkipSelf, LOCALE_ID } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { ErrorHandler } from '@angular/core';

// Sentry
import * as Sentry from '@sentry/angular';
import { BrowserTracing } from '@sentry/tracing';

// Elastic APM
// import { ApmModule } from '@elastic/apm-rum-angular';
// import { ApmErrorHandler } from '@elastic/apm-rum-angular';

// Firebase
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireAuthGuard } from '@angular/fire/auth-guard';
import { AngularFireDatabaseModule } from '@angular/fire/database';

// Coreui
import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';

// 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
// import { ChartsModule } from 'ng2-charts';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ToastrModule } from 'ngx-toastr';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { IsLoadingPipeModule } from '@service-work/is-loading';
import { QrCodeModule } from 'ng-qrcode';

// import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
// import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
// const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  //   suppressScrollX: true
// };

// Guards
import { AuthGuard, UnidadeGuard, PermissionGuard } from 'src/app/core/guards';

import { environment } from 'src/environments/environment';

// Services
import {
  AuthService, AuthApiService, CrossDomainStorageService, BroadcasterService, InactiveCheckService,
  NavService, PromptUpdateService
} from 'src/app/core/services';

// Views
import {
  P404Component, P500Component, LoginComponent,
  OrganizationsComponent, RegisterComponent,
  ChangePasswordComponent, JoinComponent,
  AppRegisterComponent
  // ForgotPasswordComponent
} from 'src/app/core/views';

import { AuthInterceptor } from 'src/app/core/interceptor';
import { PermissionService } from 'src/app/shared/services';

registerLocaleData(localePt);

if (environment.production) {
  Sentry.init({
    dsn: environment.bugTrackingUrl,
    debug: false,
    release: `${environment.prefix}@${environment.version}`,
    environment: environment.qa ? 'qa' : 'prod',

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    integrations: [
      new BrowserTracing({
        tracePropagationTargets: ['localhost', environment.urlAPI],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
      new Sentry.Replay()
    ],
    // initialScope: {
    //   tags: { 'url-base': environment.urlBase }
    // },

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
  });
}

const VIEWS = [
  LoginComponent,
  OrganizationsComponent,
  P404Component,
  P500Component,
  RegisterComponent,
  ChangePasswordComponent,
  JoinComponent,
  AppRegisterComponent
  // ForgotPasswordComponent
];

const GUARDS = [
  AuthGuard,
  UnidadeGuard,
  PermissionGuard
];

const SERVICES = [
  NavService,
  AuthService,
  AuthApiService,
  BroadcasterService,
  CrossDomainStorageService,
  InactiveCheckService,
  PermissionService,
  PromptUpdateService
];

@NgModule({
  imports: [
    // ApmModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,

    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireDatabaseModule,

    AppBreadcrumbModule.forRoot(),
    TabsModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
      enableHtml: true
    }),
    BsDropdownModule.forRoot(),
    IsLoadingPipeModule,
    QrCodeModule
  ],
  declarations: [
    ...VIEWS
  ],
  exports: [
    // ApmModule,
    AppAsideModule,
    AppBreadcrumbModule,
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    BrowserAnimationsModule,
    BrowserModule,
    BsDropdownModule,
    // ChartsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    PerfectScrollbarModule,
    TabsModule,
    IsLoadingPipeModule,

    AngularFireModule,
    AngularFireAuthModule,
    AngularFireDatabaseModule,

    ...VIEWS,
  ],
  providers: [
    // { provide: ErrorHandler, useClass: ApmErrorHandler },
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
        dialogOptions: {
          lang: 'pt-BR',
          title: 'Parece que houve um problema.',
          subtitle: 'Nosso time foi notificado. ',
          subtitle2: 'Se você quiser ajudar, conte-nos o que aconteceu abaixo:',
          labelName: 'Seu Nome',
          labelEmail: 'E-mail',
          labelComments: 'O que aconteceu?',
          labelClose: 'Fechar',
          labelSubmit: 'Enviar',
          // errorGeneric: 'Eu cliquei no botão "X" e aconteceu o problema.',
          // errorFormEntry: 'Eu cliquei no botão "X" e aconteceu o problema.',
          successMessage: 'Sua mensagem foi enviada, iremos verificar o que aconteceu. Obrigado!',
        }
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    ...GUARDS,
    ...SERVICES
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule já foi carregado. Você deveria importar este módulo apenas em AppModule.');
    }
  }
}
