<div class="app-body">
  <main class="main d-flex align-items-center">
    <div class="container">
      <div class="row">
        <div class="col-md-6 mx-auto">
          <div class="card mx-4">
            <div class="card-body p-4">
              <form>
                <div class="row">
                  <div class="col-9 pr-0">
                    <h1>Baixe o App</h1>
                    <p class="text-muted">Baixe através de sua Loja de Aplicativos</p>
                  </div>
                  <div class="col-3 text-right pl-0">
                    <img src="assets/img/brand/logo-ladder.png" style="height: 70px" alt="Logo" />
                  </div>
                </div>
                <div class="row">
                  <div class="col center-item">
                    <div class="row center-item">
                      <qr-code [value]="androidURL" size="150" errorCorrectionLevel="M"
                        centerImageSize="30" centerImageSrc="./assets/img/android.png">
                      </qr-code>
                    </div>
                    <div class="row center-item">
                      <a [href]="androidURL">
                        <i class="bi bi-google-play"></i>
                        Google Play
                      </a>
                    </div>
                  </div>
                  <div class="col center-item">
                    <div class="row center-item">
                      <qr-code [value]="iosURL" size="150" errorCorrectionLevel="M"
                        centerImageSize="30" centerImageSrc="./assets/img/ios.png">
                      </qr-code>
                    </div>
                    <div class="row center-item">
                      <a [href]="iosURL">
                        <i class="fab fa-app-store-ios"></i>
                        Apple Store
                      </a>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>
