import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-register',
  templateUrl: 'app-register.component.html',
  styleUrls: ['app-register.component.scss']
})
export class AppRegisterComponent implements OnInit {

  androidURL = 'https://play.google.com/store/apps/details?id=br.dev.ladder.socios';
  iosURL = 'https://apps.apple.com/us/app/ladder-socios/id123456789';

  constructor( ) { }

  public ngOnInit(): void {
    //
  }

}
