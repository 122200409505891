export const MoneyFormat = (value: number, style?: string): string => {
  return (+(value || 0)).toLocaleString('pt-BR', {
    style: style || 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2
  });
};

export const CalendarPtBrConfig = {
  firstDayOfWeek: 0,
  dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
  dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
  dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
  monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro',
    'Outubro', 'Novembro', 'Dezembro'],
  monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
  today: 'Hoje',
  clear: 'Limpar'
};

export const pad = function(value: number, length: number): string {
  let s = String(value);
  while (s.length < (length || 2)) {s = '0' + s; }
  return s;
};
