export const LightenColor = (color: string, percent: number) => {
  const num = parseInt(color.replace('#', ''), 16),
    amt = Math.round(2.55 * percent),
    // tslint:disable-next-line: no-bitwise
    R = (num >> 16) + amt,
    // tslint:disable-next-line: no-bitwise
    B = (num >> 8 & 0x00FF) + amt,
    // tslint:disable-next-line: no-bitwise
    G = (num & 0x0000FF) + amt;

  const rCalc = R < 255 ? R < 1 ? 0 : R : 255;
  const bCalc = B < 255 ? B < 1 ? 0 : B : 255;
  const gCalc = G < 255 ? G < 1 ? 0 : G : 255;

  return `#${(0x1000000 + rCalc * 0x10000 + bCalc * 0x100 + gCalc).toString(16).slice(1)}`;
};

function padZero(str: string, len: number = 2) {
  len = len || 2;
  var zeros = new Array(len).join('0');
  return (zeros + str).slice(-len);
}

export const InvertHexColor = (hex: string, blackWhite: boolean = true) => {
  if (hex.indexOf('#') === 0) {
    hex = hex.slice(1);
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }

  if (hex.length !== 6) {
    throw new Error('Invalid HEX color.');
  }

  const r = parseInt(hex.slice(0, 2), 16);
  const g = parseInt(hex.slice(2, 4), 16);
  const b = parseInt(hex.slice(4, 6), 16);

  // http://stackoverflow.com/a/3943023/112731
  if (blackWhite) {    
    return (r * 0.299 + g * 0.587 + b * 0.114) > 186
      ? '#000000'
      : '#FFFFFF';
  }

  // invert color components
  const rStr = (255 - r).toString(16);
  const gStr = (255 - g).toString(16);
  const bStr = (255 - b).toString(16);

  // pad each with zeros and return
  return "#" + padZero(rStr) + padZero(gStr) + padZero(bStr);
}

export const InvertRgbaColor = (color: string, blackWhite: boolean = true) => {
  const rgba = color.replace(/^rgba?\(|\s+|\)$/g, '').split(',');

  const r = +rgba[0];
  const g = +rgba[1];
  const b = +rgba[2];
  const a = +rgba[3]; 

  // http://stackoverflow.com/a/3943023/112731
  if (blackWhite) {    
    return (r * 0.299 + g * 0.587 + b * 0.114 * a) > 110
      ? '#000000'
      : '#FFFFFF';
  }

  // invert color components
  const rStr = (255 - r).toString(16);
  const gStr = (255 - g).toString(16);
  const bStr = (255 - b).toString(16);

  // pad each with zeros and return
  return "#" + padZero(rStr) + padZero(gStr) + padZero(bStr);
}

export const convertHexToRGBA = (hexCode: String, opacity: number) => {
  let hex = hexCode.replace('#', '');
  
  if (hex.length === 3) {
      hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }    
  
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `rgba(${r},${g},${b},${opacity / 100})`;
};