import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { INavData } from '@coreui/angular';

@Injectable({ providedIn: 'root' })
export class NavService {
  private navBehaviorSubject = new BehaviorSubject<INavData[]>(null);

  setNav(nav: INavData[]) {
    this.navBehaviorSubject.next(nav);
  }

  getNav() {
    return this.navBehaviorSubject;
  }
}
