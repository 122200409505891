import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';

export interface Option {
  origin: string;
  path?: string;
  storage?: string;
}

interface Message {
  id: number;
  origin: string;
  type: 'set' | 'get' | 'remove';
  key: string;
  resolve: any;
  reject: any;
  value: any;
  storage: string;
}

interface IStorage {
  getItem(message: Message): void;
  setItem(message: Message): void;
  removeItem(message: Message): void;
}

class IFrameStorage implements IStorage {
  private iframe: any | null = null;
  private iframeReady = false;
  private queue: Message[] = [];
  private messages: { [key: number]: Message } = {};

  constructor(private option: Option) {
    this.init();
  }

  private init(): void {
    if (!this.iframe) {
      this.iframe = document.createElement('iframe');
      this.iframe.style.cssText = 'position:absolute;width:1px;height:1px;left:-9999px;';
      document.body.appendChild(this.iframe);

      if (window.addEventListener) {
        this.iframe.addEventListener('load', () => this.iframeLoaded(), false);
        window.addEventListener('message', event => this.handleMessage(event), false);
      } else if (this.iframe.attachEvent) {
        this.iframe.attachEvent('onload', () => this.iframeLoaded(), false);
        window['attachEvent']('onmessage', event => this.handleMessage(event));
      }

      this.iframe.src = `${this.option.origin}${this.option.path}`;
    }
  }

  private sendMessage(message: Message): void {
    if (this.iframe && this.iframe.contentWindow) {
      this.messages[message.id] = message;

      const data = JSON.stringify({
        id: message.id,
        type: message.type,
        key: message.key,
        storage: message.storage,
        value: message.value,
      });

      this.iframe.contentWindow.postMessage(data, this.option.origin);
    }
  }

  private handleMessage(event: MessageEvent): void {
    if (event.origin === this.option.origin) {
      try {
        const data = JSON.parse(event.data);
        const message = this.messages[data.id];

        if (!!data && !!message && data.key === message.key) {
          if (message.type === 'get') {
            message.resolve(JSON.parse(data.value));
          } else if (['set', 'remove'].includes(message.type) && data.value) {
            message.resolve();
          } else {
            message.reject();
          }
        }

        delete this.messages[data.id];
      } catch (error) {
        console.log(event);
      }
    }
  }

  private iframeLoaded(): void {
    this.iframeReady = true;
    this.queue.forEach(item => this.sendMessage(item));
    this.queue = [];
  }

  public getItem(message: Message): void {
    if (this.iframeReady) {
      this.sendMessage(message);
    } else {
      this.queue.push(message);
    }
  }

  public setItem(message: Message): void {
    if (this.iframeReady) {
      this.sendMessage(message);
    } else {
      this.queue.push(message);
    }
  }

  public removeItem(message: Message): void {
    if (this.iframeReady) {
      this.sendMessage(message);
    } else {
      this.queue.push(message);
    }
  }
}

class NativeStorage implements IStorage {

  constructor(private option: Option) { }

  public getItem(message: Message): void {
    const data = window[this.option.storage].getItem(message.key);
    message.resolve(JSON.parse(data));
  }

  public setItem(message: Message): void {
    window[this.option.storage].setItem(message.key, message.value);
    message.resolve();
  }

  public removeItem(message: Message): void {
    window[this.option.storage].removeItem(message.key);
    message.resolve();
  }
}

@Injectable()
export class CrossDomainStorageService {

  LAST_ACTION_KEY = 'last_action';

  private option: Option;
  private idCount: number;
  private storage: IStorage;
  private asyncValues: { [key: string]: any } = {};

  constructor() {
    this.option = {
      origin: environment.CrossDomainStorageOrigin,
      path: '/cross-domain-storage/',
      storage: 'localStorage'
    };

    this.idCount = 0;
    if (environment['electron'] || !this.supported() || !this.option.origin) {
      this.storage = new NativeStorage(this.option);
    } else {
      this.storage = new IFrameStorage(this.option);
    }
  }

  private supported(): boolean {
    return window['JSON'] && this.option.storage in window && window[this.option.storage];
  }

  public updateLastUserAction(): void {
    this.setItem(this.LAST_ACTION_KEY, Date.now());
  }

  public preLoad(keys: string[]): Promise<any> {
    const list = keys.map(key => this.getItem(key));
    return Promise.all(list);
  }

  public getItemAsync(key: string): any {
    return this.asyncValues[key];
  }

  public getItem<T>(key: string): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      this.storage.getItem({
        type: 'get',
        id: ++this.idCount,
        origin: this.option.origin,
        storage: this.option.storage,
        resolve: resolve,
        reject: reject,
        key: key,
        value: null,
      });
    }).then(item => {
      this.asyncValues[key] = item;
      return Promise.resolve(item);
    });
  }

  public setItem<T>(key: string, value: T): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.storage.setItem({
        type: 'set',
        id: ++this.idCount,
        origin: this.option.origin,
        storage: this.option.storage,
        resolve: resolve,
        reject: reject,
        key: key,
        value: JSON.stringify(value),
      });

      this.asyncValues[key] = value;
    });
  }

  public removeItem(key: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.storage.removeItem({
        type: 'remove',
        id: ++this.idCount,
        origin: this.option.origin,
        storage: this.option.storage,
        resolve: resolve,
        reject: reject,
        key: key,
        value: null,
      });

      delete this.asyncValues[key];
    });
  }
}
