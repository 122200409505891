import { ToastrMsgService } from 'src/app/shared/services/toastr-msg.service';
import { httpErrorMsgs } from 'src/app/core/utils/htp-error';

export const showErrorInToastr = (toast: ToastrMsgService, error: any, fieldMap = {}): string => {
  const erros = httpErrorMsgs(error, fieldMap);
  let result = '';
  erros.forEach(err => {
    toast.error(err.msg, err.title);
    const msg = err.title ? `${err.title}: ${err.msg}` : err.msg;
    result += msg + '\n';
  });
  return result;
};
