<div class="app flex-row align-items-center">
  <div class="container">
      <div class="justify-content-center">
          <div class="card" *ngIf="showOrganizacao; else listUnidades">
              <div class="card-body organizacao">
                  <h5 class="card-title">Organizações</h5>
                  <h6 class="card-subtitle mb-2 text-muted">Seleciona uma organização</h6>

                  <ul class="list-group">
                      <li class="list-group-item click list-group-item-action flex-column align-items-start pb-1"
                          *ngFor="let organizacao of organizacoes" (click)="listarUnidades(organizacao, $event)">
                          <div class="d-flex full-width justify-content-between">
                              <h5 class="mb-1">{{ nomeFantasia(organizacao) }}</h5>
                              <small>{{ unidadeCountText(organizacao) }}</small>
                          </div>
                          <p class="mb-1">{{ organizacao.name }}</p>
                          <small>{{ organizacao.ctr }}</small>
                      </li>
                  </ul>
              </div>
          </div>

          <ng-template #listUnidades>
              <div class="card">
                  <div class="card-body organizacao">
                      <h4>Unidades</h4>
                      <p class="text-muted">Seleciona uma unidade</p>

                      <ul class="list-group">
                          <li class="list-group-item click voltar" *ngIf="voltarUnidade" (click)="voltarOrganizacoes($event)">
                              <h5 class="mb-0">
                                  <i class="fa fa-chevron-left" aria-hidden="true"></i> Organizações
                              </h5>
                          </li>
                          <li class="list-group-item click list-group-item-action flex-column align-items-start"
                              *ngFor="let unidade of unidades" (click)="setUnidadeSelecionada(unidade, $event)">
                              <div class="d-flex full-width justify-content-between">
                                  <h5 class="mb-1">{{ unidade.name }}</h5>
                              </div>
                              <small>{{ unidade.ctr }}</small>
                          </li>
                      </ul>
                  </div>
              </div>
          </ng-template>
      </div>
  </div>
</div>
