import { Injectable } from '@angular/core';
import { map, filter } from 'rxjs/operators';

import { Subject, Observable } from 'rxjs';

interface BroadcastEvent {
  key: any;
  data?: any;
}

@Injectable()
export class BroadcasterService {
  static instance: BroadcasterService;
  private eventBus: Subject<BroadcastEvent>;

  constructor() {
    if (!BroadcasterService.instance) {
      this.eventBus = new Subject<BroadcastEvent>();
      BroadcasterService.instance = this;
    }

    return BroadcasterService.instance;
  }

  public broadcast(key: any, data?: any): void {
    this.eventBus.next({ key, data });
  }

  public on<T>(key: any): Observable<T> {
    return this.eventBus.asObservable().pipe(
      filter(event => event.key === key),
      map(event => <T>event.data));
  }
}
