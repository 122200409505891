import { Injectable } from '@angular/core';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';

import { PermissionService } from './permission.service';
import { ToastrMsgService } from './toastr-msg.service';

export interface InsertOrUpdate {
  namespace: string;
  action: string | string[];
}

export interface Options {
  route: ActivatedRoute;
  insert?: InsertOrUpdate;
  update?: InsertOrUpdate;
  paramName?: string;
}

export interface OptionsDef {
  route: ActivatedRoute;
  namespace: string;
  prefix: string;
  paramName?: string;
}

@Injectable()
export class RouterService {
  constructor(
    private router: Router,
    private toastrMsg: ToastrMsgService,
    private permissionService: PermissionService,
  ) { }

  public InsertOrUpdate(options: Options): Promise<InsertOrUpdate> {
    return new Promise<InsertOrUpdate>((resolve, reject) => {
      const id = +options.route.snapshot.params[options.paramName || 'id'];
      resolve(id > 0 ? options.update : options.insert);
    });
  }

  public InsertOrUpdateDef(options: OptionsDef): Promise<InsertOrUpdate> {
    const insert = { namespace: options.namespace, action: `${options.prefix}_create` };
    const update = { namespace: options.namespace, action: `${options.prefix}_update` };
    return this.InsertOrUpdate({ route: options.route, insert, update, paramName: options.paramName });
  }

  public navigate(namespace: string, action: string | string[], commands: any[], extras?: NavigationExtras): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.permissionService.hasPermission(namespace, action).subscribe((hasPerm) => {
        if (hasPerm) {
          this.router.navigate(commands, extras).then(resolve).catch(reject);
        } else {
          this.warning();
          resolve(false);
        }
      }, err => {
        this.warning();
        reject(false);
      });
    });
  }

  private warning(): void {
    this.toastrMsg.warning('Você não tem permissão para realizar esta operação!', 'Permissão Negada');
  }
}
