import { Injectable, OnDestroy, ApplicationRef } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { Subscription, concat, interval } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable()
export class PromptUpdateService implements OnDestroy {

  private subs: Subscription;

  constructor(private appRef: ApplicationRef, private updates: SwUpdate) {
    this.subs = this.updates.available.subscribe(event => {
      const msg = `Existe uma nova versão disponível!\nDeseja Atualizar?`;
      console.log('Nova Versão:', msg, event.available.hash, event.available.appData);
      if (confirm(msg)) {
        this.updates.activateUpdate().then(() => document.location.reload());
      }
    });

    if (this.updates.isEnabled) {
      // this.updates.checkForUpdate()
      //   .then(() => console.log('Verificando por atualizações...'))
      //   .catch((err) => console.log('Erro ao verificar atualizações', err));

      // Allow the app to stabilize first, before starting polling for updates with `interval()`.
      const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable === true));
      const everySixHours$ = interval(6 * 60 * 60 * 1000);
      const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);
        everySixHoursOnceAppIsStable$.subscribe(() => this.updates.checkForUpdate());
    }

  }

  checkForUpdate(): Promise<void> {
    if (this.updates.isEnabled) {
      return this.updates.checkForUpdate();
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
