import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthApiService } from 'src/app/core/services/auth-api.service';
import { httpErrorMsgs } from 'src/app/core/utils/htp-error';

@Component({
  selector: 'app-login',
  templateUrl: 'login.component.html'
})
export class LoginComponent implements OnInit {
  public form: FormGroup;
  public error: string;
  public showPwd: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthApiService,
    private fb: FormBuilder,
  ) { }

  public ngOnInit(): void {
    this.form = this.fb.group({
      username: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required]],
    });

    this.authService.lastLogin().then(username => {
      if (username) {
        this.form.patchValue({ username: atob(username) });
      }
    });
  }

  public onSubmit() {
    this.error = null;

    const value = this.form.value;
    this.authService.login(value.username, value.password).subscribe(ok => {
      if (ok) {
        const navigate = this.route.snapshot.queryParams['returnUrl'];
        this.router.navigateByUrl(!navigate ? '/' : navigate);
        // this.router.navigateByUrl(!navigate ? '/' : atob(navigate));
      } else {
        this.loginError('Usuário e/ou senha inválidos.');
      }
    }, err => {
      this.loginError(err);
    });
  }

  public loginError(error: Error | string) {
    if (typeof (error) === 'string') {
      this.error = <string>error;
      return;
    }

    const htpErros = httpErrorMsgs(<Error>error, {
      email: 'E-mail',
      password1: 'Senha',
      password2: 'Confirmação da Senha',
      change_code: 'Código de Alteração'
    });

    this.error = '';
    htpErros.forEach(err => {
      const msg = err.title ? `${err.title}: ${err.msg}` : err.msg;
      this.error += msg + '\n';
    });
  }

  // public forgotPassword(): void {
  //   this.router.navigateByUrl('/forgot-password');
  // }
}
