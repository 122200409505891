import { timer, Observable, throwError, pipe } from 'rxjs';
import { mergeMap, retryWhen } from 'rxjs/operators';

class BackOff {
  maxRetryAttempts: number;
  scalingDuration: number;
  includedStatusCodes: number[];

  constructor() {
    this.maxRetryAttempts = 3;
    this.scalingDuration = 1000;
    this.includedStatusCodes = [502, 503, 504];
  }
}

export const retryStrategy = (config?: BackOff) => (attempts: Observable<any>) => {
  config = config ? config : new BackOff();

  return attempts.pipe(
    mergeMap((error, i) => {
      const retryAttempt = i + 1;

      if (retryAttempt > config.maxRetryAttempts || !config.includedStatusCodes.find(e => e === error.status)) {
        return throwError(error);
      }

      console.log(`${error.status}. Tentativa: ${retryAttempt}. Tentando novamente em: ${retryAttempt * config.scalingDuration}ms`);
      return timer(retryAttempt * config.scalingDuration);
    }),
  );
};


export function backoff(config?: BackOff) {
  config = config ? config : new BackOff();

  return pipe(
    retryWhen(
      retryStrategy(config)
    )
  );
}

