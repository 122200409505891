
import { of as observableOf, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  CanActivate, CanActivateChild, Router,
  ActivatedRouteSnapshot, RouterStateSnapshot
} from '@angular/router';

import { PermissionService } from 'src/app/shared/services/permission.service';

@Injectable()
export class PermissionGuard implements CanActivate, CanActivateChild {
  constructor(
    private permissionService: PermissionService,
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this._permission(route, state);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this._permission(childRoute, state);
  }

  private _permission(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean> {
    if (!route.data.permission) {
      return observableOf(true);
    }

    return new Observable<boolean>(observer => {
      const { namespace, action } = route.data.permission;

      this.permissionService.hasPermission(namespace, action).subscribe((ok) => {
        if (!ok) {
          this.router.navigate(['/']);
        }

        observer.next(ok);
        observer.complete();
      });
    });
  }
}
