import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthApiService } from 'src/app/core/services/auth-api.service';
import { httpErrorMsgs } from 'src/app/core/utils/htp-error';

@Component({
  selector: 'app-change-password',
  templateUrl: 'change-password.component.html'
})
export class ChangePasswordComponent implements OnInit {
  public form: FormGroup;
  public error: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthApiService,
    private fb: FormBuilder,
  ) { }

  public ngOnInit(): void {
    this.form = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
      password1: [null, [Validators.required]],
      password2: [null, [Validators.required]],
      change_code: [this.route.snapshot.params['code'], [Validators.required]],
    });

    this.authService.lastLogin().then(username => {
      if (username) {
        this.form.patchValue({ email: atob(username) });
      }
    });
  }

  public onSubmit() {
    this.error = null;

    const value = this.form.value;

    if (value.password1 !== value.password2) {
      this.setError('As senhas devem ser iguais!');
      return;
    }

    this.authService.changePassword(value).subscribe(ok => {
      if (ok) {
        this.router.navigateByUrl('/login');
      } else {
        this.setError('Usuário ou Chave de Acesso inválidos.');
      }
    }, err => {
      this.setError(err);
    });
  }

  public setError(error: Error | string) {
    if (typeof (error) === 'string') {
      this.error = <string>error;
      return;
    }

    const htpErros = httpErrorMsgs(<Error>error, {
      email: 'E-mail',
      'password1': 'Senha',
      'password2': 'Confirmação da Senha',
      'change_code': 'Código de Alteração'
    });

    console.log(htpErros);

    this.error = '';
    htpErros.forEach(err => {
      const msg = err.title ? `${err.title}: ${err.msg}` : err.msg;
      this.error += msg + '\n';
    });
  }
}
