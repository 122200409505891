import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Permission } from 'src/app/shared/models/permission.model';
import { SessionService } from './session.service';

export const checkPermission = (perm: Permission, namespace: string, action: string | string[]): boolean => {
    if (!perm || !namespace || perm.namespace !== namespace) {
        return false;
    }

    if (!action) {
        return true;
    }

    if (Array.isArray(action)) {
        return (action.includes(perm.action));
    }

    return action === perm.action;
};

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

    constructor(
        private sessionService: SessionService
    ) { }

    public checkPermission(namespace: string, action: string | string[]): boolean {
        if (this.sessionService.data) {
            return this.sessionService.data.permissions.some(perm => checkPermission(perm, namespace, action));
        }

        return false;
    }

    public hasPermission(namespace: string, action: string | string[]): Observable<boolean> {
        return new Observable<boolean>(observer => {
            this.sessionService.userData().subscribe(userData => {
                const hasPerm = userData.permissions.some(perm => checkPermission(perm, namespace, action));
                observer.next(hasPerm);
                observer.complete();
            });
        });
    }

    public hasPermissionList(namespace: string, actions: string[]): Observable<any> {
        return new Observable<any>(observer => {
            this.sessionService.userData().subscribe(userData => {
                const permissions = actions.reduce((ret, item) => {
                    ret[item] = userData.permissions.some(perm => checkPermission(perm, namespace, item));
                    return ret;
                }, {});

                observer.next(permissions);
                observer.complete();
            });
        });
    }
}
