import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { Organization } from 'src/app/shared/models/organization.model';
import { Unit } from 'src/app/shared/models/unit.model';
import { SessionService, UNIDADE_KEY } from 'src/app/shared/services/session.service';
import { CrossDomainStorageService } from 'src/app/core/services/cross-domain-storage.service';

@Component({
  selector: 'app-organizations',
  templateUrl: './organizations.component.html',
  styleUrls: ['./organizations.component.scss']
})
export class OrganizationsComponent implements OnInit {
  public showOrganizacao = true;
  public voltarUnidade = true;
  public organizacoes: Organization[];
  public unidades: Unit[];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private clientHttp: HttpClient,
    private sessionService: SessionService,
    private storageService: CrossDomainStorageService
  ) { }

  public ngOnInit(): void {
    this.clientHttp.get<Organization[]>('/api/accounts/user/units/').pipe(
      map(res => res.sort((a, b) => this.nomeFantasia(a).localeCompare(this.nomeFantasia(b))))
    ).subscribe(organizacoes => {
      const list = organizacoes.filter(organizacao => {
        return organizacao && organizacao.units && organizacao.units.length > 0;
      });

      if (list.length === 1) {
        this.voltarUnidade = false;
        this.listarUnidades(list[0]);
      }

      this.organizacoes = list;
    });
  }

  public unidadeCountText(organizacao: Organization): string {
    if (organizacao.units.length < 2) {
      return '';
    }

    return `${organizacao.units.length} Unidades`;
  }

  public nomeFantasia(item: Organization): string {
    return item.trading_name || item.name;
  }

  public listarUnidades(organizacao: Organization): void {
    if (organizacao.units.length === 1) {
      this.setUnidadeSelecionada(<Unit>organizacao.units[0]);
      return;
    }

    this.unidades = <Unit[]>organizacao.units;
    this.showOrganizacao = false;
  }

  public voltarOrganizacoes(): void {
    this.showOrganizacao = true;
    this.unidades = null;
  }

  public setUnidadeSelecionada(unidade: Unit): void {
    this.storageService.setItem(UNIDADE_KEY, `${unidade.id}`).then(() => {
      this.sessionService.clear();
      const navigate = this.route.snapshot.queryParams['returnUrl'];
      this.router.navigateByUrl(!navigate ? '/' : navigate);
      // this.router.navigateByUrl(!navigate ? '/' : atob(navigate));
    });
  }
}
