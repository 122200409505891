import { Provider } from '@angular/core';

export const MASK_PHONE_FIXO = ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
export const MASK_PHONE_CEL = ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

export const MASK_DATE = [/[0-3]/, /[0-9]/, '/', /[0-1]/, /[0-9]/, '/', /\d/, /\d/, /\d/, /\d/];

export const MASK_TIME = [/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/, ':', /[0-5]/, /[0-9]/];
export const MASK_TIME_HH_MM = [/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/];

export const MASK_DATE_TIME = MASK_DATE.concat([' '], MASK_TIME_HH_MM);

export const MASK_CPF = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];
export const MASK_CNPJ = [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/];

export const MASK_CEP = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]


export const MASK_PROVIDE: Provider[] = [
  { provide: 'maskPhoneFixo', useValue: MASK_PHONE_FIXO },
  { provide: 'maskPhoneCel', useValue: MASK_PHONE_CEL },
  { provide: 'maskDate', useValue: MASK_DATE },
  { provide: 'maskTime', useValue: MASK_TIME },
  { provide: 'maskTimeHHMM', useValue: MASK_TIME_HH_MM },
  { provide: 'maskDateTime', useValue: MASK_DATE_TIME },
  { provide: 'maskCpf', useValue: MASK_CPF },
  { provide: 'maskCnpj', useValue: MASK_CNPJ },
  { provide: 'maskCep', useValue: MASK_CEP },
];
