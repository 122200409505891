<div class="app-body">
  <main class="main d-flex align-items-center">
    <div class="container">
      <div class="row">
        <!-- <div class="col-md-8 mx-auto"> -->
        <div class="col-md-6 mx-auto">
          <div class="card-group">
            <div class="card p-4">
              <div class="card-body">
                <form [formGroup]="form" (submit)="onSubmit()">
                  <div class="row">
                    <div class="col-8">
                      <h1>Alterar Senha</h1>
                      <p class="text-muted">Altere sua senha!</p>
                    </div>
                    <div class="col-4 text-right">
                      <img src="assets/img/brand/logo-ladder.png" style="height: 70px" alt="Logo" />
                    </div>
                  </div>
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="icon-user"></i></span>
                    </div>
                    <input type="text" class="form-control" placeholder="Usuário" autocomplete="username"
                      formControlName="email" required>
                  </div>

                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="icon-lock"></i></span>
                    </div>
                    <input type="password" class="form-control" placeholder="Nova Senha"
                      formControlName="password1" required>
                  </div>

                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="icon-lock"></i></span>
                    </div>
                    <input type="password" class="form-control" placeholder="Confirmar Nova Senha"
                      formControlName="password2" required>
                  </div>

                  <div class="alert alert-danger mb-3" *ngIf="!!error">{{ error }}</div>

                  <div class="row mt-1">
                    <div class="col-8">
                      <button type="submit" class="btn btn-primary px-4"
                        [ngClass]="{'btn-secondary': form.invalid, 'btn-primary': form.valid}"
                        [disabled]="form.invalid">
                        Alterar Senha
                      </button>
                    </div>
                    <div class="col-4 text-right">
                      <button type="button" class="btn btn-link px-0" routerLink="/login">Login</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>
