import { HttpClient, HttpParams } from '@angular/common/http';
import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';

export abstract class ApplicationApiService {
  protected http: HttpClient;
  protected router: Router;

  constructor(protected injector: Injector) {
    this.http = injector.get(HttpClient);
    this.router = this.injector.get(Router);
  }

  protected createHttpParams(data: any): HttpParams {
    let params = new HttpParams();
    if (data == null) { return params; }

    Object.keys(data).forEach(key => {
      const value = data[key];

      if (Array.isArray(value)) {
        value.forEach(item => {
          params = params.has(key) ? params.append(key, item) : params.set(key, item);
        });
      } else {
        params = params.set(key, data[key]);
      }
    });
    return params;
  }

  protected handleError(error: any): Observable<any> {
    console.log('ERRO NA REQUISIÇÃO => ', error);

    if (!!error && error.status === 401 && !!error.error && error.error.detail === 'Token inválido.') {
      const queryParams = {returnUrl: this.router.url};
      this.router.navigate(['login'], {queryParams});
      return;
    }

    return throwError(error);
  }
}

