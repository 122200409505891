import { Injector, OnInit, OnDestroy, Directive } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { INavData } from '@coreui/angular';

import { environment } from 'src/environments/environment';

import { User } from 'src/app/shared/models';
import { AuthApiService, InactiveCheckService, NavService, PromptUpdateService } from 'src/app/core/services';
import { SessionService, RenameCaptionService, PermissionService } from 'src/app/shared/services';

const B_URL = environment['urlBase'];

let DEF_HREF = ``;
DEF_HREF += window.location.hostname ? window.location.hostname : '';
DEF_HREF += window.location.port && window.location.port !== '80' ? `:${window.location.port}` : '';
DEF_HREF = DEF_HREF !== '' ? `//${DEF_HREF}` : '';

@Directive()
export abstract class BaseLayoutComponent implements OnInit, OnDestroy {
  private inactiveCheck: Subscription;
  private updater: PromptUpdateService;

  protected router: Router;
  protected route: ActivatedRoute;
  protected authService: AuthApiService;
  protected sessionService: SessionService;
  protected inactiveCheckService: InactiveCheckService;
  protected navService: NavService;
  protected renameCaptionService: RenameCaptionService;
  protected permissionService: PermissionService;

  public sidebarMinimized = false;
  public _navItems: INavData[];
  public headerNavItems: INavData[];
  public currentDate: Date;
  public electron = false;
  public organizacaoLink: string;
  public user: User;
  public userName = 'Invalid user';
  public unitName: string;
  public tmLogout = 0;
  public appVersion: string;
  public backgroundTitleCls: string | null = null;

  constructor(
    protected injector: Injector,
    public navItems: INavData[],
  ) {
    if (environment['electron']) {
      this.electron = true;
      this.organizacaoLink = null;
    } else if (!environment.production) {
      this.organizacaoLink = `${DEF_HREF}/organizations`;
    } else {
      this.organizacaoLink = !B_URL ? `${DEF_HREF}/organizations` : `//${B_URL}/organizations`;
    }
    this.currentDate = new Date();

    this.appVersion = environment.version;
  }

  ngOnInit(): void {
    this.router = this.injector.get(Router);
    this.route = this.injector.get(ActivatedRoute);
    this.authService = this.injector.get(AuthApiService);
    this.sessionService = this.injector.get(SessionService);
    this.inactiveCheckService = this.injector.get(InactiveCheckService);
    this.navService = this.injector.get(NavService);
    this.renameCaptionService = this.injector.get(RenameCaptionService);
    this.permissionService = this.injector.get(PermissionService);
    this.updater = this.injector.get(PromptUpdateService);

    if (!environment.production) {
      this.backgroundTitleCls = 'debug-alert-header-color';
    } else if (environment.prefix.endsWith('-qa')) {
      this.backgroundTitleCls = 'qa-alert-header-color';
    }

    this._navItems = this.navItems;

    this.setInfo();
    this.navService.setNav(this.navItems);
    this.navService.getNav().subscribe(items => {
      this.headerNavItems = [];

      let _items = [];
      if (items && items.length > 0) {
        _items = [...items];
      } else {
        // Usa itens originais
        _items = [...this._navItems];
      }

      this.navItems = this.renameItems(this.allowedItems(_items));
      this.headerNavItems.sort((a, b) => a.name.localeCompare(b.name));
    });
  }

  ngOnDestroy(): void {
    if (this.inactiveCheck) {
      this.inactiveCheck.unsubscribe();
    }
  }

  private checkPermission(item: INavData): boolean {
    const attributes = item.attributes ? item.attributes : {};
    const perm = attributes['permission'];
    const perms = attributes['permissions'];
    if (!perm && !perms) {
      return true;
    }

    const permissions = [];

    if (!!perm) {
      permissions.push(perm);
    } else if (!!perms) {
      perms.forEach(item => permissions.push(item));
    }

    return permissions.find(perm => this.permissionService.checkPermission(perm['namespace'], perm['action']));
  }

  private isHeaderMenu(item: INavData): boolean {
    const attributes = item.attributes ? item.attributes : {};
    return !!attributes['headerNav'];
  }

  private allowedItems(items: INavData[]): INavData[] {
    const allowedList = [];
    items.forEach(item => {
      if (this.checkPermission(item)) {
        if (item.children) {
          item.children = this.allowedItems(item.children);
        }

        // Se não houver link, existir lista filhos mas nenhum filho, não adiciona na lista
        if (item.url === '.' && item.children && item.children.length === 0) {
          return;
        }

        allowedList.push(item);
      }
    });
    return allowedList;
  }

  private renameItems(items: INavData[]): INavData[] {
    const renamedItems = [];
    items.forEach(item => {
      if (this.isHeaderMenu(item)) {
        let headerNavItem = item;

        // Caso exista um "Name" diferente para o Header
        if (item.attributes['headerNavName']) {
          headerNavItem = { ...item };
          headerNavItem.name = this.renameCaptionService.getCaption(headerNavItem.attributes['headerNavName']);
        }
        this.headerNavItems.push(headerNavItem);
      }

      item.name = this.renameCaptionService.getCaption(item.name);
      if (item.children) {
        item.children = this.renameItems(item.children);
      }
      renamedItems.push(item);
    });
    return renamedItems;
  }

  private startInactiveCheck(): void {
    if (this.tmLogout > 0) {
      this.inactiveCheck = this.inactiveCheckService.event().pipe(
        filter(time => this.tmLogout !== 0 && time > this.tmLogout))
        .subscribe(time => {
          this.logout();
        });
      this.inactiveCheckService.start();
    }
  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  public checkForUpdate(): void {
    this.updater.checkForUpdate();
  }

  public setInfo(): void {
    // Initial value
    this.tmLogout = 15 * 60 * 1000;
    this.startInactiveCheck();

    this.sessionService.userData().subscribe(userData => {
      const name = userData.unit.trading_name || userData.unit.name;
      this.unitName = `${userData.unit.organization.trading_name || userData.unit.name}`;

      if (userData.unit.organization.units.length > 1) {
        this.unitName += ` (${name})`;
      }

      this.user = userData.user;
      this.tmLogout = (userData.user.session_time || 15) * 60 * 1000;

      if (userData.user.first_name) {
        this.userName = (userData.user.first_name.split(' ')[0]) || 'error';
      } else if (userData.user.email) {
        this.userName = (userData.user.email.split('@')[0]) || 'error';
      }
    });
  }

  public logout(): void {
    this.authService.logout()
      .subscribe(() => {
        // this.router.navigate(['/login']);
      });
  }
}
