import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
// import { map, mergeMap } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

import { CrossDomainStorageService } from 'src/app/core/services/cross-domain-storage.service';
import { TOKEN_NAME, UNIDADE_KEY } from 'src/app/shared/services/session.service';
import { InactiveCheckService } from 'src/app/core/services';
// import { AuthService } from 'src/app/core/services';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private storageService: CrossDomainStorageService,
    private inactiveCheckService: InactiveCheckService
    // private authService: AuthService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    this.inactiveCheckService.updateLastUserAction();

    let reqUrl = req.url;
    if ((environment.production) || (window.location.protocol === 'file:')) {
      reqUrl = environment.urlAPI + req.url;
    }

    let reqClone = req.clone({
      url: reqUrl,
      headers: req.headers.set('x-host', environment.urlBase)
    });
    if (reqClone.headers.has('no-xhrbackend')) {
      reqClone = reqClone.clone({ headers: reqClone.headers.delete('no-xhrbackend') });
      // console.log('Removido xhr-backend', reqClone);
      return next.handle(reqClone);
    }

    const unitKey = this.storageService.getItemAsync(UNIDADE_KEY);
    if (!!unitKey) {
      reqClone = reqClone.clone({ headers: reqClone.headers.set('Operation-Unit', unitKey) });
    }

    if (reqClone.headers.has('no-authorization')) {
      reqClone = reqClone.clone({ headers: reqClone.headers.delete('no-authorization') });
    } else {
      if (!reqClone.headers.has('Authorization')) {
        const tokenKey = this.storageService.getItemAsync(TOKEN_NAME);
        if (tokenKey && tokenKey !== '') {
          reqClone = reqClone.clone({ headers: reqClone.headers.set('Authorization', `Token ${tokenKey}`) });
        }
        // return this.authService.token.pipe(
        //   map(token => {
        //     return reqClone.clone({
        //       headers: reqClone.headers.set('Authorization', `JWT ${token}`)
        //     });
        //   }),
        //   mergeMap(data => next.handle(data))
        // );
      }
    }

    // console.log('intercept-end', reqClone);
    return next.handle(reqClone);
  }
}
