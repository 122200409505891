import { HttpErrorResponse } from "@angular/common/http";

const IGNORE_TITLES = ['detail', 'non_field_errors'];
const ERRO_GENERICO = 'Entre em contato com o suporte.';

export class HttpErrorMsgs {
  msg: string;
  title?: string;
}

const getError = (key: string, value: string | any[] | Object, fieldMap = {}): HttpErrorMsgs[] => {
  if (Array.isArray(value)) {
    return getArrayError(key, value, fieldMap);
  } else if (typeof value === 'object') {
    return getObjectError(value, fieldMap);
  } else if (typeof value === 'string') {
    const title = IGNORE_TITLES.indexOf(key) !== -1 ? 'Erro' : fieldMap[key] ? fieldMap[key] : key;
    const err = new HttpErrorMsgs();
    err.msg = value;
    err.title = title;
    return [err];
  } else {
    const err = new HttpErrorMsgs();
    err.msg = ERRO_GENERICO;
    err.title = 'Erro desconhecido!';
    return [err];
  }
};

const getArrayError = (key: string, array: Array<any>, fieldMap = {}): HttpErrorMsgs[] => {
  const erros = [];
  array.forEach(item => {
    getError(key, item, fieldMap).forEach(err => {
      erros.push(err);
    });
  });
  return erros;
};

const getObjectError = (object: Object, fieldMap = {}): HttpErrorMsgs[] => {
  const erros = [];
  Object.keys(object).forEach(key => {
    getError(key, object[key], fieldMap).forEach(err => {
      erros.push(err);
    });
  });
  return erros;
};

export const httpErrorMsgs = (error: Error, fieldMap = {}): HttpErrorMsgs[] => {
  try {
    let value = error;
    if (typeof error === 'object' && error['_body']) {
      value = error;
    }
    if (typeof error === 'object' && error['error']) {
      value = error['error'];
      if (typeof value === 'object' && value['error']) {
        value = value['error'];
      }
    }
    return getError('', value, fieldMap);
  } catch (e) {
    console.error('Erro ao processar JSON: ', e);
    const err = new HttpErrorMsgs();
    err.msg = ERRO_GENERICO;
    err.title = 'Erro desconhecido!';
    return [err];
  }

};
