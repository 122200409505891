// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const firebaseConfig = {
  apiKey: 'AIzaSyA3too8X8-7BUlAk0B6oPpnpvIIrzzWEjE',
  authDomain: 'ladder-apps.firebaseapp.com',
  databaseURL: 'https://ladder-apps.firebaseio.com',
  projectId: 'ladder-apps',
  storageBucket: 'ladder-apps.appspot.com',
  messagingSenderId: '776244788962',
  appId: '1:776244788962:web:e0f29db43a80e3879728bb',
  measurementId: 'G-253VSP71WY'
};

export const envDef = {
  prefix: '',
  version: '',
  production: true,
  qa: false,
  electron: false,
  urlAPI: 'http://localhost:4200',
  urlBase: 'localhost:4200',
  CrossDomainStorageOrigin: 'http://localhost:8000',
  firebase: firebaseConfig,
  bugTrackingUrl: 'https://03b8c2420c3049b58b8b56f2d82d1855@o4504684355649536.ingest.sentry.io/4504685792722944'
};
