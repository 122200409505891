import { BehaviorSubject } from 'rxjs';
import { Injectable } from "@angular/core";

@Injectable()
export class ResourceIdListService {
  private resourcesList = new BehaviorSubject<number[]>(null);

  setResourceIdList(resources: number[]) {
    this.resourcesList.next(resources);
  }

  getResourceIdList(): BehaviorSubject<number[]> {
    return this.resourcesList;
  }
}
