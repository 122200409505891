import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { ConvenioLayoutComponent } from 'src/app/containers/convenio-layout';

// Guards
import { AuthGuard, PermissionGuard, UnidadeGuard } from 'src/app/core/guards';

// Pages
import {
  P404Component, P500Component
} from 'src/app/core/views';

import { baseRoutes } from 'src/app/apps/shared/base-routes';

export const routes: Routes = [
  ...baseRoutes,
  {
    path: '',
    component: ConvenioLayoutComponent,
    data: { title: 'Home' },
    canActivate: [AuthGuard, UnidadeGuard],
    canActivateChild: [PermissionGuard],
    children: [
      { path: '', redirectTo: '/covenant/dashboard', pathMatch: 'full' },
      { path: 'dashboard', redirectTo: '/covenant/dashboard', pathMatch: 'full' },
      {
        path: 'configuracao',
        loadChildren: () => import('./modules/accounts/accounts.module').then(m => m.AccountsModule)
      }, {
        path: 'covenant',
        loadChildren: () => import('./modules/covenant/covenant.module').then(m => m.CovenantModule)
      },
      {
        path: '404',
        component: P404Component,
        data: { title: 'Não Encontrato' }
      },
      {
        path: '500',
        component: P500Component,
        data: { title: 'Erro' }
      },
      { path: '**', component: P404Component }
    ]
  },
  {
    path: '404', pathMatch: 'full', data: { title: 'Não Encontrato' },
    component: P404Component,
  },
  {
    path: '500', pathMatch: 'full', data: { title: 'Erro' },
    component: P500Component,
  },
  // { path: '**', redirectTo: '' },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class ConvenioRoutingModule { }
