import { Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError, tap, share, finalize } from 'rxjs/operators';

import { FindApiInterface } from 'src/app/shared/interfaces/find-api.interface';
import { ApplicationApiService } from './application-api.service';
import { BaseModel } from 'src/app/shared/models/base.model';
import { ResponsePagination } from 'src/app/shared/utils/pagination';
import { backoff, setFieldObjectId } from 'src/app/shared/utils';

export abstract class BaseApiService<T extends BaseModel> extends ApplicationApiService implements FindApiInterface<T> {
  private cachedSaveObservable: Observable<any>;
  public canDoCache: boolean = true;

  constructor(
    protected apiPath: string,
    protected injector: Injector,
    protected jsonDataToResourceFn?: (jsonData: any) => T
  ) {
    super(injector);
  }

  protected jsonDataToResource(jsonData: any): T {
    if (this.jsonDataToResourceFn) {
      return this.jsonDataToResourceFn(jsonData);
    }
    return jsonData as T;
  }

  protected beforeSaveData(obj: T): T {
    // Pode ser implementado nos controles filhos para manipulação do objeto antes de salvar
    return obj;
  }

  protected afterSaveData(obj: T): T {
    // Pode ser implementado nos controles filhos para manipulação do objeto depois de salvar
    return obj;
  }

  protected prepareData(obj: T): T {
    // Pode ser implementado nos controles filhos para manipulação do objeto
    return obj;
  }

  protected mapComplete(item: any): any {
    return {
      value: item.id,
      text: item.full_name,
    };
  }

  protected setFieldObjectId(obj, field: string): any {
    return setFieldObjectId(obj, field);
  }

  public find(params: { [key: string]: any | any[]; }, keyPath: string = ''): Observable<T[]> {
    return this.http.get(`${this.apiPath}${keyPath}`, { params: params }).pipe(
      map(res => {
        let json_data = res || [];
        if (Object.keys(json_data).indexOf('results') !== -1) {
          json_data = json_data['results'];
        }
        return json_data;
      }),
      map((items: Array<any>) => items.map(item => this.prepareData(this.jsonDataToResource(item)))),
      backoff(),
      catchError(this.handleError.bind(this))
    );
  }

  public formSearchType<R>(params: { [key: string]: string; },
      fromJsonFn: (jsonData) => R, url: string | null = null): Observable<ResponsePagination<R>> {
    return this.http.get<ResponsePagination<R>>(url || this.apiPath, { params: params }).pipe(
      map(res => {
        res.results = res.results.map(item => fromJsonFn(item));
        return res;
      }),
      backoff(),
      catchError(this.handleError.bind(this))
    );
  }

  public formSearch(params: { [key: string]: string; }): Observable<ResponsePagination<T>> {
    return this.http.get<ResponsePagination<T>>(this.apiPath, { params: params }).pipe(
      map(res => {
        res.results = res.results.map(item => this.prepareData(this.jsonDataToResource(item)));
        return res;
      }),
      backoff(),
      catchError(this.handleError.bind(this))
    );
  }

  public findById(itemId: number | string, params: { [key: string]: string; } = {}): Observable<T> {
    return this.http.get<T>(`${this.apiPath}${itemId}/`, {params: params}).pipe(
      map(res => this.prepareData(this.jsonDataToResource(res))),
      backoff(),
      catchError(this.handleError.bind(this))
    );
  }

  public save(obj: T): Observable<T> {

    let observable: Observable<any>;

    if (this.canDoCache && this.cachedSaveObservable) {
      observable = this.cachedSaveObservable;
    } else {
      const data = this.beforeSaveData(obj);

      if (!data.id) {
        this.cachedSaveObservable = this.cachedSaveObservable = this.http.post<T>(this.apiPath, data).pipe(
          map(res => {
            const savedData = this.afterSaveData(this.jsonDataToResource(res));
            return this.prepareData(savedData);
          }),
          share(),
          catchError(this.handleError.bind(this)),
          finalize(() => this.cachedSaveObservable = null)
        );
      } else {
        this.cachedSaveObservable = this.http.put<T>(`${this.apiPath}${data.id}/`, data).pipe(
          map(res => {
            const savedData = this.afterSaveData(this.jsonDataToResource(res));
            return this.prepareData(savedData);
          }),
          share(),
          backoff(),
          catchError(this.handleError.bind(this)),
          finalize(() => this.cachedSaveObservable = null)
        );
      }

      observable = this.cachedSaveObservable;
    }
    return observable;
  }

  public destroy(itemId: number): Observable<boolean> {
    return this.http.delete(`${this.apiPath}${itemId}/`).pipe(
      map(res => {
        console.log(res);
        // res.status === 204
        return true;
      }),
      backoff(),
      catchError(this.handleError.bind(this))
    );
  }
}
