import { Injectable, Injector } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApplicationApiService } from 'src/app/shared/services/application-api.service';
import { BroadcasterService } from 'src/app/core/services/broadcaster.service';
import { SessionService, TOKEN_NAME, LAST_LOGIN } from 'src/app/shared/services/session.service';
import { CrossDomainStorageService } from 'src/app/core/services/cross-domain-storage.service';
import { InactiveCheckService } from 'src/app/core/services/inactive-check.service';
import { AuthService } from 'src/app/core/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthApiService extends ApplicationApiService {
  private logged: boolean;

  constructor(
    private authService: AuthService,
    private sessionService: SessionService,
    private storageService: CrossDomainStorageService,
    private broadcasterService: BroadcasterService,
    private inactiveCheckService: InactiveCheckService,
    protected injector: Injector
  ) {
    super(injector);
    this.logged = false;
  }

  public lastLogin(): Promise<string> {
    return this.sessionService.lastLogin();
  }

  public login(username: string, password: string): Observable<boolean> {
    return new Observable<boolean>(observer => {
      this.http.post('/api/accounts/auth/login/', { username, password }).pipe(
        map(res => res)
      ).subscribe(res => {
        const token = this.setToken(res['token']);
        this.inactiveCheckService.reset();
        this.storageService.setItem(LAST_LOGIN, btoa(username));

        if (!token) {
          this.logged = false;
          this.handlerError(observer, false);
          return;
        }

        this.authService.login(username, password);

        this.logged = true;
        this.handlerNext(observer, true);
      }, (err) => {
        if (err.status >= 400 && err.status < 500) {
          this.handlerError(observer, 'Usuário e/ou senha inválidos.');
        } else {
          this.handlerError(observer, 'Ocorreu um erro ao realizar login!');
        }
      });
    });
  }

  public logout(): Observable<boolean> {
    return new Observable<boolean>(observer => {
      this.broadcasterService.broadcast('logout');

      // TODO: Verificar retorno booleano
      this.http.delete('/api/accounts/auth/logout/').subscribe(res => {
        this.clear();

        this.authService.logout();

        if (res !== null) {
          observer.next(true);
          observer.complete();
          return;
        }

        observer.next(false);
        observer.complete();
      }, () => {
        observer.next(false);
        observer.complete();
      });
    });
  }

  public requestChangePassword(email: string): Observable<any[]> {
    return this.http.post<any[]>(`/api/accounts/auth/user/request-change-pwd/`, { email }).pipe(
      map((res) => res)
    );
  }

  public changePassword(obj): Observable<any[]> {
    return this.http.post<any[]>(`/api/accounts/auth/user/change-pwd/`, obj).pipe(
      map(res => res)
    );
  }

  public join(obj): Observable<any> {
    return this.http.post(`/api/accounts/auth/join/${obj.invite_code}/`, obj).pipe(
      map(res => res)
    );
  }

  public register(obj): Observable<any> {
    return this.http.post(`/api/accounts/auth/register/${obj.invite_code}/`, obj).pipe(
      map(res => res)
    );
  }

  public SetLogged(logged: boolean) {
    this.logged = logged;
  }

  public isLogged(): boolean {
    return this.logged;
  }

  public clear() {
    this.logged = false;
    this.storageService.removeItem(TOKEN_NAME);
    // this.inactiveCheckService.reset();
    this.sessionService.clear();
  }

  private setToken(token) {
    this.storageService.setItem(TOKEN_NAME, token);
    return !token ? null : token;
  }

  private handlerNext(observer: Subscriber<any>, value: any) {
    observer.next(value);
    observer.complete();
  }

  private handlerError(observer: Subscriber<any>, value: any) {
    observer.error(value);
    observer.complete();
  }
}
