import { envDef, firebaseConfig } from 'src/environments/env.default';

export const environment = {
    ...envDef,
    prefix: 'convenio',
    version: '1.0.68',
    urlAPI: 'https://convenio.prod.api.gestor.ladder.dev.br',
    urlBase: 'convenio.app',
    CrossDomainStorageOrigin: 'https://convenio.prod.api.gestor.ladder.dev.br',
    firebase: firebaseConfig
};
