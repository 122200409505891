import { AbstractControl, ValidationErrors } from "@angular/forms";
import { Moment } from "./moment";

const EMAIL_TEST =
  /^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/;
const CELULAR_TEST = /^[0-9]{2}[6789]{1}[0-9]{8}$/;

const CPF_INVALID = { appCPF: true };
const CNPJ_INVALID = { appCNPJ: true };


const isEmptyValue = (value: any) => {
  if (value === null || value === undefined || value === "") {
    return true;
  }
  return false;
};

export class FormValidators {

  static email(control: AbstractControl): ValidationErrors {
    if (isEmptyValue(control.value)) {
      return null;
    }

    return EMAIL_TEST.test(control.value) ? null : { appEmail: true };
  }

  static link(control: AbstractControl): ValidationErrors {
    if (isEmptyValue(control.value)) {
      return null;
    }

    return control.value.length > 5 ? null : { appLink: true };
  }

  static minValue(value: number) {
    return (control: AbstractControl): ValidationErrors => {
      if (isEmptyValue(control.value)) {
        return null;
      }
      return +control.value >= +value ? null : { appMinValue: true };
    };
  }

  static maxValue(value: number) {
    return (control: AbstractControl): ValidationErrors => {
      if (isEmptyValue(control.value)) {
        return null;
      }
      return +control.value > +value ? { appMaxValue: true } : null;
    };
  }

  static datetime(format?: string) {
    return (control: AbstractControl): ValidationErrors => {
      if (isEmptyValue(control.value)) {
        return null;
      }
      return !Moment(control.value, format).isValid()
        ? { appDatetime: true }
        : null;
    };
  }

  static rangeValue(min: number, max: number) {
    return (control: AbstractControl): ValidationErrors => {
      if (control.value < min) {
        return { appMinValue: true };
      }

      if (control.value > max) {
        return { appMaxValue: true };
      }

      return null;
    };
  }

  static celular(control: AbstractControl): ValidationErrors {
    if (isEmptyValue(control.value)) {
      return null;
    }

    const numero = control.value.trim().replace(/[^0-9]/g, "");
    return CELULAR_TEST.test(numero) ? null : { celular: true };
  }

  static cpf(control: AbstractControl): ValidationErrors | null {
    const cpf = (control.value || "").trim().replace(/[^0-9]/g, "");

    if (isEmptyValue(control.value)) {
      return null;
    }

    if (
      cpf.length !== 11 ||
      cpf === "00000000000" ||
      cpf === "11111111111" ||
      cpf === "22222222222" ||
      cpf === "33333333333" ||
      cpf === "44444444444" ||
      cpf === "55555555555" ||
      cpf === "66666666666" ||
      cpf === "77777777777" ||
      cpf === "88888888888" ||
      cpf === "99999999999"
    ) {
      return CPF_INVALID;
    }

    // Valida primeiro digito
    const soma_d1 = cpf
      .split("")
      .splice(0, 9)
      .map((val, index) => +val * (10 - index))
      .reduce((sum, num) => sum + num, 0);

    let rev_d1 = 11 - (soma_d1 % 11);

    if (rev_d1 === 10 || rev_d1 === 11) {
      rev_d1 = 0;
    }

    if (rev_d1 !== +cpf.charAt(9)) {
      return CPF_INVALID;
    }

    // Valida segundo digito
    const soma_d2 = cpf
      .split("")
      .splice(0, 10)
      .map((val, index) => +val * (11 - index))
      .reduce((sum, num) => sum + num, 0);

    let rev_d2 = 11 - (soma_d2 % 11);
    if (rev_d2 === 10 || rev_d2 === 11) {
      rev_d2 = 0;
    }

    if (rev_d2 !== +cpf.charAt(10)) {
      return CPF_INVALID;
    }

    return null;
  }

  static cnpj(control: AbstractControl): ValidationErrors | null {
    const cnpj = (control.value || "").trim().replace(/[^\d]+/g, "");

    if (isEmptyValue(cnpj)) {
      return null;
    }

    // Elimina CNPJs invalidos conhecidos
    if (
      cnpj.length !== 14 ||
      cnpj === "00000000000000" ||
      cnpj === "11111111111111" ||
      cnpj === "22222222222222" ||
      cnpj === "33333333333333" ||
      cnpj === "44444444444444" ||
      cnpj === "55555555555555" ||
      cnpj === "66666666666666" ||
      cnpj === "77777777777777" ||
      cnpj === "88888888888888" ||
      cnpj === "99999999999999"
    ) {
      return CNPJ_INVALID;
    }

    // Valida primeiro digito
    let digitos = cnpj.split("").map((val) => +val);
    let numeros = digitos.splice(0, 12);
    let posAux = 5;

    const soma_d1 = numeros.reduce((sum, num) => {
      sum += num * posAux--;
      if (posAux < 2) {
        posAux = 9;
      }
      return sum;
    }, 0);

    const resultado_d1 = soma_d1 % 11 < 2 ? 0 : 11 - (soma_d1 % 11);

    if (resultado_d1 !== digitos[0]) {
      return CNPJ_INVALID;
    }

    // Valida segundo digito
    digitos = cnpj.split("").map((val) => +val);
    numeros = digitos.splice(0, 13);
    posAux = 6;

    const soma_d2 = numeros
      .map((val) => +val)
      .reduce((sum, num) => {
        sum += num * posAux--;
        if (posAux < 2) {
          posAux = 9;
        }
        return sum;
      }, 0);

    const resultado_d2 = soma_d2 % 11 < 2 ? 0 : 11 - (soma_d2 % 11);
    if (resultado_d2 !== digitos[0]) {
      return CNPJ_INVALID;
    }

    return null;
  }
}
