import { Injectable } from '@angular/core';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';

import { BehaviorSubject } from 'rxjs';
import { filter, distinctUntilChanged } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class PreviousRouteService {
  public previousUrl: BehaviorSubject<string>;
  private currentUrl: string;

  constructor(private router: Router) {

    this.currentUrl = this.router.url;
    this.previousUrl = new BehaviorSubject(null);

    this.router.events
      .pipe(
        filter((event: RouterEvent) => event instanceof NavigationEnd),
        distinctUntilChanged((prev, curr) => prev.url === curr.url)
      )
      .subscribe((event: NavigationEnd) => {
        const currentUrl = this.currentUrl.split('?')[0].split('/');
        const nextUrl = event.urlAfterRedirects.split('?')[0].split('/');

        const diff = currentUrl.filter(a => !nextUrl.includes(a));
        if (this.currentUrl !== event.urlAfterRedirects && (diff.length === 0 || diff[0] !== 'new')) {
          this.previousUrl.next(this.currentUrl);
        }

        this.currentUrl = event.urlAfterRedirects;
      });

  }
}
