import { INavData } from '@coreui/angular';

export const navItems: INavData[] = [
  {
    name: 'Dashboard',
    url: '/covenant/dashboard',
    icon: 'icon-speedometer',
    attributes: { permission: { namespace: 'covenant' } },
  },
  {
    name: 'Atendimento',
    url: '/covenant/attendance',
    icon: 'far fa-address-book',
    attributes: { permission: { namespace: 'covenant', action: 'simple_attendance_create' } },
  },
  {
    name: 'Atender Requisição',
    url: '/covenant/request-attendance',
    icon: 'fa fa-user-md',
    attributes: { permission: { namespace: 'covenant', action: 'request_attend' } },
  },
  {
    name: 'Requisição',
    url: '/covenant/request',
    icon: 'far fa-file-alt',
    attributes: { permission: { namespace: 'covenant', action: 'request_list' } },
  },
  {
    name: 'Cadastros',
    icon: 'fas fa-bars',
    url: '.',
    children: [
      {
        name: 'Categorias',
        url: '/covenant/category',
        icon: 'fa fa-list',
        attributes: { permission: { namespace: 'covenant', action: 'category_list' } },
      },
      {
        name: 'Credenciamento',
        url: '/covenant/accreditation',
        icon: 'fas fa-file-import',
        attributes: { permission: { namespace: 'covenant', action: 'accreditation_list' } },
      },
      {
        name: 'Emissor',
        url: '/covenant/emitter',
        icon: 'far fa-edit',
        attributes: { permission: { namespace: 'covenant', action: 'emitter_list' } },
      },
      {
        name: 'Prestador',
        url: '/covenant/provider',
        icon: 'fa fa-user-md',
        attributes: { permission: { namespace: 'covenant', action: 'service_provider_list' } },
      },
      {
        name: 'Procedimentos',
        url: '/covenant/procedure',
        icon: 'fa fa-user-md',
        attributes: { permission: { namespace: 'covenant', action: 'procedure_list' } },
      },
      // {
      //   name: 'Convênio Simples',
      //   url: '/covenant/simple-covenant',
      //   icon: 'fas fa-project-diagram',
      //   attributes: { permission: { namespace: 'covenant', action: 'simple_covenant_create' } },
      // },
      {
        name: 'Tabela de Preços',
        url: '/covenant/provider-table',
        icon: 'fas fa-dollar-sign',
        attributes: { permission: { namespace: 'covenant', action: 'service_provider_table_price_list' } },
      },
    ]
  },
  {
    name: 'Relatórios',
    url: '/covenant/report',
    icon: 'fas fa-print',
    attributes: { permission: { namespace: 'covenant', action: 'request_list' } },
  },
];
