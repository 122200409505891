<div class="app-body">
  <main class="main d-flex align-items-center">
    <div class="container">
      <div class="row">
        <div class="col-md-6 mx-auto">
          <div class="card mx-4">
            <div class="card-body p-4">
              <form [formGroup]="form" (submit)="onSubmit()">
                <div class="row">
                  <div class="col-9 pr-0">
                    <h1>Cadastre-se</h1>
                    <p class="text-muted">Crie sua conta para acesso ao sistema</p>
                  </div>
                  <div class="col-3 text-right pl-0">
                    <img src="assets/img/brand/logo-ladder.png" style="height: 70px" alt="Logo" />
                  </div>
                </div>
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="icon-user"></i></span>
                  </div>
                  <input type="text" class="form-control" placeholder="Nome"
                    formControlName="first_name" required>
                </div>
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="icon-user"></i></span>
                  </div>
                  <input type="text" class="form-control" placeholder="Sobrenome"
                    formControlName="last_name">
                </div>
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text">@</span>
                  </div>
                  <input type="text" class="form-control" placeholder="Email"
                  formControlName="email" autocomplete="email" required>
                </div>
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="icon-lock"></i></span>
                  </div>
                  <input type="password" class="form-control" placeholder="Senha"
                    formControlName="password" autocomplete="new-password" required>
                </div>
                <div class="input-group mb-4">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="icon-lock"></i></span>
                  </div>
                  <input type="password" class="form-control" placeholder="Repita a senha"
                    formControlName="confirm_password" autocomplete="new-password" required>
                </div>

                <div class="alert alert-danger mb-3" *ngIf="!!error">{{ error }}</div>

                <button type="submit" class="btn btn-block btn-success"
                  [ngClass]="{'btn-secondary': form.invalid, 'btn-primary': form.valid}"
                  [disabled]="form.invalid">Criar conta</button>
              </form>
            </div>
            <!-- <div class="card-footer p-4">
              <div class="row">
                <div class="col-4">
                  <button class="btn btn-block btn-google-plus" type="button"><span>google</span></button>
                </div>
                <div class="col-4">
                  <button class="btn btn-block btn-facebook" type="button"><span>facebook</span></button>
                </div>
                <div class="col-4">
                  <button class="btn btn-block btn-twitter" type="button"><span>twitter</span></button>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </main>
</div>
