import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ToastrMsgService {

    constructor(protected toastr: ToastrService) { }

    /** show successful toast */
    success(
        message?: string,
        title?: string,
    ) {
        this.toastr.success(message, title);
    }

    /** show error toast */
    error(
        message?: string,
        title?: string,
    ) {
        this.toastr.error(message, title);
    }
    /** show info toast */
    info(
        message?: string,
        title?: string,
    ) {
        this.toastr.info(message, title);
    }
    /** show warning toast */
    warning(
        message?: string,
        title?: string,
    ) {
        this.toastr.warning(message, title);
    }
}
