import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

import { CrossDomainStorageService } from './cross-domain-storage.service';

const CHECK_INTERVAL = 15000; // in ms
const MIN_INTERVAL_SEND = 2000; // in ms
const LOCK_KEY = 'last_action_lock';

@Injectable()
export class InactiveCheckService {
  static instance: InactiveCheckService;
  private started = false;
  private eventBus: Subject<number>;
  private interval: any = null;
  private lockCount = 0;

  constructor(
    private storageService: CrossDomainStorageService
  ) {
    if (!InactiveCheckService.instance) {
      this.eventBus = new Subject<number>();
      InactiveCheckService.instance = this;
    }

    return InactiveCheckService.instance;
  }

  private run(): void {
    if (this.started) { return; }
    this.started = true;

    // window.onload = this.updateLastEvent.bind(this);
    // window.onmousemove = this.updateLastEvent.bind(this);
    // window.onmousedown = this.updateLastEvent.bind(this);
    // window.onclick = this.updateLastEvent.bind(this);
    // window.onscroll = this.updateLastEvent.bind(this);
    // window.onkeypress = this.updateLastEvent.bind(this);

    window.addEventListener('beforeunload', (event) => {
      this.storageService.getItem<number>(LOCK_KEY).then(lock => {
        this.storageService.setItem(LOCK_KEY, lock - this.lockCount);
        this.lockCount = 0;
      });
    });

    this.check();
    this.interval = setInterval(() => this.check(), 1000 * 60);
  }

  // private updateLastEvent(event: Event): void {
  //   this.storageService.setItem(LAST_ACTION_KEY, Date.now());
  // }

  private check() {
    this.storageService
      .getItem<number>(LOCK_KEY)
      .then(lock => +lock > 0 ? Promise.resolve(-1) : this.storageService.getItem<number>(this.storageService.LAST_ACTION_KEY))
      .then(last => {
        if (last !== -1) {
          const time = Date.now() - (!last ? Date.now() : last);

          if (time > MIN_INTERVAL_SEND) {
            this.eventBus.next(time);
          }
        }
      });
  }

  public updateLastUserAction(): void {
    if (this.started) {
      this.storageService.updateLastUserAction();
    }
  }

  public start(): void {
    this.check();
    this.run();
  }

  public stop(): void {
    clearTimeout(this.interval);
    this.interval = null;
    this.started = false;
    this.lockCount = 0;
  }

  public reset(): void {
    this.stop();
    this.storageService.removeItem(this.storageService.LAST_ACTION_KEY);
    this.storageService.removeItem(LOCK_KEY);
  }

  public event(): Observable<any> {
    return this.eventBus.asObservable();
  }

  public lock(): void {
    this.storageService.getItem<number>(LOCK_KEY).then(lock => {
      this.lockCount++;
      this.storageService.setItem(LOCK_KEY, ++lock);
    });
  }

  public unlock(): void {
    this.storageService.getItem<number>(LOCK_KEY).then(lock => {
      this.lockCount--;
      this.storageService.setItem(LOCK_KEY, --lock);
    });
  }
}
