import { Injectable } from '@angular/core';
import {
  CanActivate, CanActivateChild, Router, ActivatedRouteSnapshot, RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';

import { AuthApiService } from 'src/app/core/services';
import { SessionService } from 'src/app/shared/services/session.service';

@Injectable()
export class UnidadeGuard implements CanActivate, CanActivateChild {
  constructor(
    private sessionService: SessionService,
    private authService: AuthApiService,
    private router: Router
  ) { }

  canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this._unidade(state);
  }

  canActivateChild(_childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this._unidade(state);
  }

  private _unidade(state: RouterStateSnapshot): Observable<boolean> {
    return new Observable<boolean>(observer => {
      this.sessionService.userData().subscribe(userData => {
        observer.next(true);
        observer.complete();
      }, () => {
        const queryParams = {};

        if (state.url && !['', '/'].includes(state.url)) {
          queryParams['returnUrl'] = state.url;
        }

        if (this.authService.isLogged()) {
          this.router.navigate(['organizations'], {queryParams});
        } else {
          this.router.navigate(['login'], {queryParams});
        }
        observer.next(false);
        observer.complete();
      });
    });
  }
}
