import { FuncItemAction, ValueFunc, ConditionFunc, ValueFieldFunc } from './func';

export type GridColumType = null | 'boolean' | 'options' | 'select';
export type GridColumFormat = null | 'currency' | 'money' | 'date' | 'datetime' | 'phone' | 'number';

export class GridColumn {
  constructor(
    public field: string,
    public title?: string,

    public clickEvent?: FuncItemAction,
    public cls?: string,
    public getCls?: ValueFieldFunc,
    public complement?: ValueFunc,
    public format?: GridColumFormat,
    // public hide?: boolean,
    public style?: Object,
    public type?: GridColumType,
    public valueCallback?: ValueFunc,

    public icon?: string,
  ) { }
}

export class GridTotal {
  constructor(
    public cls: string,
    public format: GridColumFormat,
    public title: string,
    public style: Object,
    public type: GridColumType,
    public value: string
  ) { }
}

export class GridOption {
  constructor(
    public icon: string,
    public title: string,
    public clickEvent: FuncItemAction,
    public namespace?: string,
    public action?: string,
    public visible?: boolean,
    public condition?: ConditionFunc
  ) {
    this.visible = true;
  }
}
