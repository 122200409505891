export const MergeDateTime = (dateSource: Date, timeSource: Date): Date => {
  dateSource = new Date(dateSource);
  timeSource = new Date(timeSource);

  const timeStr =
    timeSource.getHours() +
    ":" +
    timeSource.getMinutes() +
    ":" +
    timeSource.getSeconds();
  const dateStr =
    dateSource.getUTCFullYear() +
    "-" +
    (dateSource.getUTCMonth() + 1) +
    "-" +
    dateSource.getUTCDate();

  return new Date(dateStr + " " + timeStr);
};

export const msToTime = (duration: number): string => {
  // const milliseconds = parseInt((duration % 1000) / 100);
  const hours = ("0" + Math.floor((duration / (1000 * 60 * 60)) % 24)).slice(
    -2
  );
  const minutes = ("0" + Math.floor((duration / (1000 * 60)) % 60)).slice(-2);
  const seconds = ("0" + Math.floor((duration / 1000) % 60)).slice(-2);

  return `${hours}:${minutes}:${seconds}`;
};

export const secondsToTime = (value: number): string => {
  const hours = ("0" + Math.floor(value / 3600)).slice(-2);
  const minutes = ("0" + Math.floor((value / 60) % 60)).slice(-2);
  const seconds = ("0" + Math.floor(value % 60)).slice(-2);

  return `${hours}:${minutes}:${seconds}`;
};

export const isValidDate = (d: any): boolean => {
  return !isNaN(d) && d instanceof Date;
};

export const calendarPtBrConfig = {
  firstDayOfWeek: 0,
  dayNames: [
    "Domingo",
    "Segunda",
    "Terça",
    "Quarta",
    "Quinta",
    "Sexta",
    "Sábado",
  ],
  dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
  dayNamesMin: ["D", "S", "T", "Q", "Q", "S", "S"],
  monthNames: [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ],
  monthNamesShort: [
    "Jan",
    "Fev",
    "Mar",
    "Abr",
    "Mai",
    "Jun",
    "Jul",
    "Ago",
    "Set",
    "Out",
    "Nov",
    "Dez",
  ],
  today: "Hoje",
  clear: "Limpar",
};
