export const getattr = (obj: any, props: string, def: any = null): any => {
  const arrPprop = (props || '').split('.');
  return arrPprop.reduce((val, key) => val && val[key] ? val[key] : null, obj) || def;
};

export const trim = (value: string, char: string): string => {
  return value
    .replace(new RegExp('^[' + char + ']+'), '')
    .replace(new RegExp('[' + char + ']+$'), '');
};

export const lpad = (str: any, length: number, char: string = '0'): string => {
  str = `${str || ''}`;
  char = `${char || ' '}`;
  length = length || 0;

  while (str.length < length) {
    str = char + str;
  }

  return str;
};

export const getLastDay = (year: number, month: number): number => {
  if ((year % 4) === 0 && month === 2) {
    return 29;
  }

  return [0, 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month];
};

export const getStartEndDate = (date: Date): { start: string, end: string } => {
  const dateExtract = date ? date : new Date();
  const month = lpad(dateExtract.getDay(), 2);
  const start = `${dateExtract.getFullYear()}-${month}-01`;
  const lastDay = getLastDay(dateExtract.getFullYear(), dateExtract.getMonth());
  const end = `${dateExtract.getFullYear()}-${month}-${lastDay}`;
  return { start, end };
};


export const OnlyValuesOfObject = (obj: Object) => {
  const newObject = {};
  Object.keys(obj).forEach(key => {
    const value = obj[key];
    if (!!value) {
      newObject[key] = value;
    }
  });

  return newObject;
};

export const setFieldObjectId = (obj, field: string): any => {
  if (!!obj[field]) {
    const isId = ['number', 'string'].indexOf(typeof obj[field]) !== -1;
    obj[`${field}_id`] = isId ? obj[field] : obj[field].id;
    // delete obj[field];
  } else {
    obj[`${field}_id`] = null;
  }
};
