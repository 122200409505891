import { Injectable } from '@angular/core';
import {
  CanActivate, CanActivateChild, Router, ActivatedRouteSnapshot, RouterStateSnapshot
} from '@angular/router';
import { of as observableOf, Observable } from 'rxjs';

import { AuthApiService } from 'src/app/core/services/auth-api.service';
import { SessionService } from 'src/app/shared/services/session.service';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(
    private router: Router,
    private sessionService: SessionService,
    private authService: AuthApiService
  ) { }

  canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this._logado(state);
  }

  canActivateChild(_childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this._logado(state);
  }

  private _logado(state: RouterStateSnapshot): Observable<boolean> {
    if (this.authService.isLogged()) {
      return observableOf(true);
    }

    return new Observable<boolean>(observer => {
      this.sessionService.userData().subscribe(() => {
        this.authService.SetLogged(true);
        observer.next(true);
        observer.complete();
      }, () => {
        const queryParams = {};

        if (state.url && !['', '/'].includes(state.url)) {
          queryParams['returnUrl'] = state.url;
          // queryParams['returnUrl'] = btoa(decodeURI(state.url));
        }

        this.router.navigate(['login'], {queryParams});
        observer.next(false);
        observer.complete();
      });
    });
  }
}
